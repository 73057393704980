import React from 'react';
import {Helmet} from "react-helmet";
import { Link, StaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import pollitoTest from "../audio/p02pollito.mp3"
import Footer from "../components/Footer"

function importAll(r) {
    let images = {};
    r.keys().map((item, index) => { images[item.replace('./', '')] = r(item); });
    return images;
}
const images = importAll(require.context('../images/', false, /.jpg$|.png/));

function animationOn(){
  console.log("hello")
}

class Page02 extends React.Component {
  constructor(props){
    super(props)
    this.state = { active: "" }
  }
  playAudio() {
    const audioEl = document.getElementsByClassName("audio-element")[0]
    audioEl.play()
    console.log(audioEl)
  }
  render() {

    return (
      <div className="main-container">
      <Link to="/">
        <div className="page-turner">
          <img style={{transform: "scaleX(-1)", opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      <Link to="/Page03">
        <div className="page-turner right-page-pos">
        <img style={{opacity: 0.75}} className="hw" src={images["arrowPages.png"]} alt="" />
        </div>
      </Link>
      		<div className="centered-container">
      		<div className="_idContainer007">
      			<div className="backContainer004">
            <StaticQuery
              query={graphql`
                query  {
                  imageOne: file(relativePath: { eq: "p02_back.jpg" }) {
                    childImageSharp {
                      fluid(maxWidth: 1000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              `}
        render={data => (
          <Img fluid={data.imageOne.childImageSharp.fluid} />
        )}
      />
      			</div>
      		<div className="_idContainer008">
      			<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p02_letreroPoste.png"]} alt="" />
      		</div>
      		<div className="_idContainer015">
      			<div className="_idContainer009">
      				<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p02_botonCuentamelo.png"]} alt="" />
      			</div>
      			<div className={"_idContainer010" + this.state.active}
                  onAnimationEnd={() => this.setState({ active: "" })}>
      				<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p02_pollito.png"]} alt="" />
      			</div>
      			<div className="_idContainer011">
      				<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p02_letrero.png"]} alt="" />
      			</div>
      			<div className="_idContainer014"
              onClick={() => { this.setState({ active: " active" }); this.playAudio()}}


              >
      				<img className="_idGenObjectAttribute-1 _idGenObjectAttribute-2" src={images["p02_cencerro.png"]} alt="" />
      			</div>
      		</div>
          <audio className="audio-element">
            <source src={pollitoTest}></source>
          </audio>
      			</div>
      			</div>
        <Footer />
    		</div>
    )
  }
}

export default Page02;
